import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'gatsby';

import './Button.scss';

const Button = ({ to, text, initiallyVisible = false, className = '' }) =>
    initiallyVisible ? (
        <Link to={to} className={`cfp-button ${className}`}>
            <span>{text}</span>
        </Link>
    ) : (
        <ScrollAnimation animateIn="fadeIn" animateOnce={true} initiallyVisible={initiallyVisible}>
            <Link to={to} className={`cfp-button ${className}`}>
                <span>{text}</span>
            </Link>
        </ScrollAnimation>
    );

export default Button;
